function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/da/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const DAssociateRoutes = [
  {
    path: '/da/dashboard',
    component: view('Dashboard'),
    name: 'da.dashboard',
    authorizedRole: 'da'
  },
  {
    path: '/da/payment-vouchers',
    component: view('PaymentVouchers'),
    name: 'da.payment-vouchers',
    authorizedRole: 'da',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payment Vouchers',
          active: true
        }
      ]
    }
  },
  // PENDING APPROVALS
  {
    path: '/da/payment-requests/document-printing/:da?',
    component: view('payment-requests/document-printing/Base'),
    name: 'da.payment-requests.document-printing',
    authorizedRole: 'da',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payment Requests'
        },
        {
          text: 'Document Printing',
          active: true
        }
      ]
    }
  },
  {
    path: '/da/payment-requests/check-signing/:da?',
    component: view('payment-requests/check-signing/Base'),
    name: 'da.payment-requests.check-signing',
    authorizedRole: 'da',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payment Requests'
        },
        {
          text: 'Check Signing',
          active: true
        }
      ]
    }
  },
  {
    path: '/da/payment-requests/releasing/:da?',
    component: view('payment-requests/releasing/Base'),
    name: 'da.payment-requests.releasing',
    authorizedRole: 'da',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Payment Requests'
        },
        {
          text: 'Releasing',
          active: true
        }
      ]
    }
  },
  {
    path: '/da/reports/revolving-funds/:da?',
    component: view('reports/RevolvingFunds'),
    name: 'da.reports.revolving-funds',
    authorizedRole: 'da',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Reports'
        },
        {
          text: 'Revolving Funds',
          active: true
        }
      ]
    }
  },
  {
    path: '/da/reports/credit-cards/:da?',
    component: view('reports/CreditCards'),
    name: 'da.reports.credit-cards',
    authorizedRole: 'da',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Reports'
        },
        {
          text: 'Credit Cards',
          active: true
        }
      ]
    }
  }
]

export default DAssociateRoutes
