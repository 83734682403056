import ApiService from '@/services/core/api.service'

const RevolvingFundService = {
  async get (query = '') {
    return ApiService.get(`disbursement-associate/reports/revolving-replenishment?${query}`)
  },

  async export (query = '') {
    return ApiService.get(`disbursement-associate/exports/revolving-replenishment?${query}`)
  },

  async post (payload) {
    return ApiService.post('disbursement-associate/reports/revolving-replenishment', payload)
  },

  async put (payload) {
    return ApiService.put(`disbursement-associate/reports/revolving-replenishment/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`disbursement-associate/reports/revolving-replenishment/${payload.id}`)
  }
}

export default RevolvingFundService
